<template>
  <div>

    <!-- Modal for messages -->
    <v-dialog v-model="messageDialog" max-width="600" persistent>
      <div>
        <MsgForm :vendor=vendor @close="clearMessage"></MsgForm>
      </div>
    </v-dialog>

    <!-- Modal for showcasework -->
    <v-dialog
        v-model="dialogDetails.show"
        width="500"
    >
      <v-card>
        <v-card-title class="headline grey lighten-2">
          {{ this.dialogDetails.name }}
        </v-card-title>

        <v-card-text>
          <v-card-subtitle>
            {{ this.dialogDetails.title }}
          </v-card-subtitle>

          {{ this.dialogDetails.description }}
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              text
              @click="dialogDetails.show = false"
          >
            {{ $t('general.close') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Modal for reviews -->
    <v-dialog
        v-model="dialogReviews"
        width="800"
    >
      <v-card class="mx-auto">
        <v-card-title class="headline grey lighten-2">
          {{ vendor.name }}

          <v-spacer></v-spacer>
          <v-btn
              text
              @click="dialogReviews = false"
          >
            {{ $t('general.close') }}
          </v-btn>
        </v-card-title>

        <v-card-text>
          <ModalReviews
              :vendor=vendor
          ></ModalReviews>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              text
              @click="dialogReviews = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Modal for request quote -->
    <v-dialog v-model="dialogQuote" max-width="600" persistent>
      <div>
        <QuoteForm :vendor=vendor @close="clearQuote" :chat=null></QuoteForm>
      </div>
    </v-dialog>


    <!--
    <VendorPackagesModal
    v-if="showPackages" v-model="showPackages"
    :products=vendor.products
    :vendorId=vendor.id
    @close="showPackages = false"></VendorPackagesModal>
    -->
    <router-link
        class="hover:tw-text-black tw-no-underline"
        exact
        :to="{ name: 'showcasework.show',
                          params: { id: vendor.companies ? vendor.companies.slug ? vendor.companies.slug:vendor.id : vendor.id }}"
        v-slot="{ href }"
        target="_blank"
        custom
    >
    <a
        :href="href"
        class="tw-relative tw-block hover:tw-no-underline"
    >
      <div class="tw-relative tw-overflow-visible">

      <div class="tw-overflow-hidden tw-rounded-lg" style="height: 200px;">
        <img
            class="tw-object-cover tw-h-full tw-w-full"

            :src="getImage(vendor.showcasework)"
        />
      </div>

        <FeedVendorLabel v-if="withLabel" :featured="vendor.featured" :spotlight="vendor.spotlight"/>

        <!-- Msg Vendor -->
        <div
            class="tw-absolute tw-top-12 tw-right-3"
        >
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <a
                  v-bind="attrs"
                  v-on="on"
                  class="tw-bg-white tw-no-underline tw-rounded-full tw-h-6 tw-w-6 tw-inline-flex tw-items-center tw-justify-center"
                  href="#"
                  @click.prevent="messageDialog=!messageDialog">

                <message-square-icon class="tw-h-2.5 tw-w-2.5 tw-text-red" />
              </a>
            </template>
            <span>Send Message to Vendor</span>
          </v-tooltip>
        </div>

        <!-- Calendar alert -->
        <!--
        <v-tooltip
            bottom
            v-if="filterParams && vendor.settings"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-chip
                v-if="vendor.settings.set_my_available_dates === false"
                class="position-absolute"
                style="bottom: 1px; right: 1px"
                label
                small
                v-bind="attrs"
                v-on="on"
                color="orange lighten-3"
                text-color="brown darken-1"
            >
              <v-icon x-small left color="orange darken-4">
                mdi-information-outline
              </v-icon>
              {{ $t('vendor.calendar') }}
              <v-icon small right>
                icon-wedding-date
              </v-icon>
            </v-chip>
          </template>
          {{ $t('vendor.detail_info') }}
        </v-tooltip>
        -->

        <!--        Categories-->
        <div
            class="tw-absolute tw-bottom-3 tw-left-3 tw-flex tw-gap-1 tw-text-xs"
            @mouseover="showCategories = true"
            @mouseout="showCategories = false"
        >
          <Category v-for="category in displayedCategories" :key="category.id" :category="category"/>
          <span
              v-if="categories.length > 3"
              class="tw-flex tw-rounded-full tw-bg-white tw-h-6 tw-w-6 tw-text-black tw-items-center tw-justify-center"
          >
            <p class="tw-m-0">+{{ categories.length - 2 }}</p>
          </span>

        </div>
        <div
            v-if="showCategories"
            class="tw-absolute tw-text-sm tw-text-black tw-p-4 tw-bg-white tw-rounded-xl tw-shadow-md tw-mt-2 tw-z-50 tw-flex tw-flex-col tw-gap-2"
        >
          <p class="tw-m-0" v-for="category in categories" :key="category.id"> {{ category.name }} </p>
        </div>




        <!-- Rating -->
        <div
            @click.prevent="dialogReviews=true"
            v-if="vendor.averageRating > 0"
            class="tw-absolute tw-bottom-3 tw-right-3 tw-bg-white tw-items-center tw-rounded-2xl tw-px-3 tw-py-1 tw-flex"
        >
          <v-rating
              :value="vendor.averageRating"
              color="red"
              background-color="red"
              class="tw-flex"
              dense
              emptyIcon='mdi-heart-outline'
              fullIcon='mdi-heart'
              halfIcon='mdi-heart-half-full'
              half-increments
              readonly
              size="10"
          ></v-rating>
          <span style="font-size: 8px" class="tw-text-red tw-font-semibold tw-ml-1">{{ vendor.averageRating }}</span>
        </div>

        <!-- Country -->
<!--        <div v-if="vendor.countries.length">

          <v-tooltip
              bottom
          >
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                  class="btn position-absolute"
                  style="top: 0px; left: 0px"

                  v-bind="attrs"
                  v-on="on"
                  dark
              >mdi-map
              </v-icon>
            </template>
            <span>{{ operateInCountries | truncate(30) }}</span>
          </v-tooltip>
        </div>-->
      </div>


      <!-- Vendor name -->
      <v-card-subtitle v-if="vendor.companies" class="tw-px-0 tw-pb-0">
        <!-- PIN -->
        <!--
        <div
            class="tw-absolute tw-top-3 tw-right-3"
        >
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <a v-if="user"
                 v-bind="attrs"
                 v-on="on"
                 class="tw-bg-white tw-no-underline tw-rounded-full tw-h-6 tw-w-6 tw-inline-flex tw-items-center tw-justify-center"
                  href="#"
                  @click.prevent="vendor.pin = !vendor.pin; pin(vendor)">

                <bookmark-icon v-if="vendor.pin" fill="currentColor" class="tw-h-2.5 tw-w-2.5 tw-text-red" />
                <bookmark-icon v-if="!vendor.pin" class="tw-h-2.5 tw-w-2.5 tw-text-red" />
              </a>
            </template>
            <span>{{ vendor.pin ? $t('vendor.unpin') : $t('vendor.pin') }}</span>
          </v-tooltip>
        </div>
        -->

        <!-- Custom Quote -->
        <div
            class="tw-absolute tw-top-3 tw-right-3"
        >
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <a
                 v-bind="attrs"
                 v-on="on"
                 class="tw-bg-white tw-no-underline tw-rounded-full tw-h-6 tw-w-6 tw-inline-flex tw-items-center tw-justify-center"
                 href="#"
                 @click.prevent="dialogQuote=!dialogQuote">
                <mail-icon class="tw-h-2.5 tw-w-2.5 tw-text-red" />
              </a>
            </template>
            <span>Request Custom Quote</span>
          </v-tooltip>
        </div>


        <h4 class="tw-font-semibold tw-text-black tw-text-base tw-leading-relaxed">{{ vendor.companies.name | truncate(32) }}</h4>

<!--        Countries and price-->
        <span class="tw-flex tw-flex-wrap tw-items-center tw-font-sm tw-text-black tw-leading-relaxed tw-mt-1 tw-block tw-justify-between">
<!--          countries-->
          <span class="tw-relative">
            <span v-if="vendor.countries.length > 0" class="tw-flex tw-items-center tw-justify-between tw-text-xs">
            <MapPinIcon class="tw-text-red tw-h-4"/>
            <span
                v-for="(country,index) in displayedCountries" :key="index"
            >
              {{country.code}}{{index != displayedCountries.length-1 ? ',&nbsp;' : ''}}
            </span>
            <span
                v-if="vendor.countries.length > 3"
                class="tw-ml-1"
                @mouseover="showCountries = true"
                @mouseout="showCountries = false"
            >
              <p class="tw-m-0">+{{ vendor.countries.length - 3 }} more</p>
            </span>

          </span>
          <div
              v-if="showCountries"
              class="tw-absolute tw-max-h-72 tw-text-sm tw-text-black tw-p-4 tw-bg-white tw-rounded-xl tw-shadow-md tw-mt-2 tw-z-50 tw-flex tw-flex-wrap tw-gap-2"
          >
            <span
                v-for="(country,index) in vendor.countries" :key="index"
            >
              {{country.code}}{{index != vendor.countries.length-1 ? ',&nbsp;' : ''}}
            </span>
          </div>
          </span>

<!--          price-->
          <span v-if="vendor.avgPrice" class="tw-flex tw-items-center tw-text-xs">
            <DollarSignIcon class="tw-text-red tw-h-4 tw-hidden"/>
          {{ $t('vendor.min') }}
          {{ vendor.minPrice | currency }}
          </span>
        </span>

      </v-card-subtitle>
      <v-card-subtitle v-else class="tw-px-0 tw-pb-0">
        <h4 class="tw-font-semibold tw-text-black">{{ vendor.name  | truncate(20) }}</h4>
      </v-card-subtitle>

<!--      <v-card-actions>
        &lt;!&ndash; Heart &ndash;&gt;
        <div v-if="user">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                  v-bind="attrs"
                  v-on="on"
                  :class="vendor.love ? 'red&#45;&#45;text' : ''"
                  icon
                  @click="vendor.love = !vendor.love, love()"
              >
                <v-icon small>mdi-heart</v-icon>
              </v-btn>
            </template>
            <span>{{ vendor.loveWeight + (vendor.love ? 1 : 0) | number('0a') }}</span>
          </v-tooltip>
        </div>

        <v-spacer></v-spacer>
        &lt;!&ndash; View showcasework &ndash;&gt;
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
                :disabled="vendor.products.length == 0 || !vendor.showcasework"
                color="orange darken-3"
                v-bind="attrs"
                v-on="on"
                x-small
                outlined
                rounded
                text

                :to="{ name: 'showcasework.show',
                          params: { id: vendor.companies ? vendor.companies.slug ? vendor.companies.slug:vendor.id : vendor.id }}"
                target="_blank"
                class="routerLink text-sm"
            >
              {{ $t('vendor.offering_detail') }}
            </v-btn>
          </template>
          {{ $t('vendor.offering_detail_tooltip') }}
        </v-tooltip>

        <v-spacer></v-spacer>

        <v-btn
            :disabled="!vendor.showcasework"
            color="orange darken-3"
            icon
            @click="showDialogDetails(vendor.name, vendor.showcasework)"
        >
          <v-icon small>mdi-tooltip-text</v-icon>
        </v-btn>
      </v-card-actions>-->
    </a>
    </router-link>
  </div>
</template>

<script>

//import VendorPackagesModal from '../components/VendorPackagesModal.vue';
//import Calendar from './Calendar.vue'
//import ShowcaseWorks from './VendorShowcaseWork.vue'
//import {HeartRating} from 'vue-rate-it';

import ModalReviews from '../components/Reviews.vue';
import QuoteForm from '../components/QuoteForm.vue';
import MsgForm from '../components/MsgForm.vue';
import { mapGetters } from 'vuex';
import { MessageSquareIcon, MailIcon, MapPinIcon, DollarSignIcon } from 'vue-feather-icons';
import FeedVendorLabel from "@/components/FeedVendorLabel";
import Category from "@/components/CategoryButton.vue"

export default {
  name: 'FeedVendor',
  props: {
    vendor: {
    },
    user: {
    },
    withLabel: {
      type: Boolean,
      default: true,
    },
  },

  components: {
    //VendorPackagesModal,
    //Calendar,
    //ShowcaseWorks,
    //HeartRating,
    ModalReviews,
    MessageSquareIcon,
    MailIcon,
    MapPinIcon,
    DollarSignIcon,
    QuoteForm,
    MsgForm,
    FeedVendorLabel,
    Category,
  },
  data() {
    return {
      uniqueParents: {},
      showCountries: false,
      showCategories: false,
      dialogDetails: {
        show: false,
        name: '',
        title: '',
        description: '',
      },
      dialogQuote: false,
      dialogReviews: false,
      showcalendar: false,
      availability: [],
      loading: false,
      showmore: false,
      showModal: false,
      showPackages: false,
      show: false,
      loveresponse: null,
      pinresponse: null,
      messageDialog : false,
      // categories: [],
    }
  },
  computed: {
    ...mapGetters({
      filterParams: 'filter/getFilterParams',
    }),
    displayedCountries() {
      return this.vendor.countries.slice(0, 3);
    },
    categories() {
      let vendorCategories = []
      if(this.vendor.categories) {
          this.vendor.categories.forEach(category => {
            if (category.parents.length > 0){
              let parentId = category.parents[0].id;
              if (!this.uniqueParents[parentId]) {
                this.uniqueParents[parentId] = true;
                vendorCategories.push({
                  id: category.parents[0].id,
                  name: category.parents[0].name,
                  mdi: category.parents[0].mdi
                });
              }
          }

          });
      }
      return vendorCategories;
    },
    displayedCategories() {
      const limit = this.categories.length > 3 ? 2 : 3;
      return this.categories.slice(0, limit);
    },
    showcaseworkImage() {
      return 'https://cdn.vuetifyjs.com/images/cards/docks.jpg';
      //var image = this.vendor.showcasework.map(a => a.image).toString();
      //return image ? image : 'https://cdn.vuetifyjs.com/images/cards/docks.jpg'

    },
    getRandImg() {
      //const images = ["feed_card_1.jpg", "feed_card_2.jpg", "feed_card_3.jpg", "feed_card_4.jpg", "feed_card_5.jpg", "vendor-coming-soon.png"];
      const images = ["vendor-coming-soon.png"];
      const random = Math.floor(Math.random() * images.length);
      return images[random];
      //console.log(images[random]);
    },
    operateInCountries() {
      var operateInCountries = this.vendor.countries.map(a => a.name).toString();
      return operateInCountries ? operateInCountries : null
    },
  },
  methods: {
    clearMessage() {
      this.messageDialog = false;
    },
    clearQuote() {
      this.dialogQuote = false;
    },
    getImage(showcasework){

      if(showcasework){
        if(showcasework.image_feed){
          return showcasework.image_feed;
        }else{
          return require('@/assets/img/wedding/'+this.getRandImg+'');
        }
      }else{
        return require('@/assets/img/wedding/'+this.getRandImg+'');
      }
    },
    showDialogDetails(vendor, value) {

      this.dialogDetails.name = vendor;
      this.dialogDetails.title = value.title;
      this.dialogDetails.description = value.description;
      this.dialogDetails.show = true;
    },
    openModal() {
      this.modalOpen = !this.modalOpen;
    },
    async showCalendar(e) {
      if (this.showcalendar) {
        this.showcalendar = false;
        this.availability = [];
        e.srcElement.style.color = ""
      } else {
        e.srcElement.style.color = "coral"
        this.loading = true;
        await this.fetchAvailability();
        this.showcalendar = true;
      }

    },
    async pin(vendor) {
      let uri = 'api/pin/' + this.vendor.id;
      //console.log(uri);
      await this.$http.post(uri)
          .then(({data}) => {
            this.pinresponse = data.data
          })
          .catch(error => {
            console.log(error)
          });
      //console.log("pinresponse",vendor.pin);
      this.$emit('pinned', {
        item: vendor
      });

    },
    async love() {
      let uri = 'api/love/' + this.vendor.id;
      //console.log(uri);
      this.$http.post(uri)
          .then(({data}) => {
            this.loveresponse = data.love
          })
          .catch(error => {
            console.log(error)
          })
    },
    async fetchAvailability() {

      const params = {
        "filter[vendor_id]": this.vendor.id
      };

      this.$http.get('api/availability', {params})
          .then(({data}) => {
            this.availability = data.data
          })
          .catch(error => {
            console.log(error)
          })
          .finally(() =>
              this.loading = false,
          )
    },
  }

}
</script>

<style scoped>
.checked {
  color: orange;
}
</style>
