<template>
  <div>
    <div class="tw-max-w-9xl tw-mx-auto tw-px-4 sm:tw-px-8 lg:tw-px-16 tw-mb-14">
      <div class="tw-relative">
        <div class="tw-relative sm:tw-rounded-lg sm:tw-overflow-hidden">
          <div class="tw-absolute tw-inset-0">
            <!--
            <img alt="" class="tw-h-full tw-w-full tw-object-cover" src="@/assets/img/landing/home/1.jpg">
            -->

              <video
                id="vid"
                autoplay
                loop
                muted
                playsinline
                class="absolute tw-z-10 tw-w-auto tw-min-w-full tw-min-h-full tw-max-w-none tw-h-full tw-w-full tw-object-cover"
            >
              <source
                  src="@/assets/video/landing_page_bkgd5.mp4"
                  type="video/mp4"
              />
              Your browser does not support the video tag.
            </video>

          </div>
          <div class="tw-absolute tw-inset-0" style="background: linear-gradient(transparent 30%, rgba(0,0,0,0.4) 60%, transparent 100%)">
          </div>
          <div style="height: 640px" class="tw-flex tw-flex-col tw-relative">

            <div class="tw-relative tw-mt-auto tw-mb-16 tw-text-white tw-text-center tw-flex tw-justify-center">

              <div class="tw-inset-0 tw-px-3 sm:tw-pb-2">
                <div class="tw-text-white tw-w-full">
                  <h1 class="tw-text-3xl sm:tw-text-2.5rem tw-mb-3 tw-leading-relaxed tw-font-extrabold">
                    BOOK Your Wedding Vendor Dream Team Here Today!
                  </h1>
                  <h2 class="tw-text-xl tw-leading-relaxed tw-font-extrabold">
                    Find available vendors across the USA
                  </h2>
                </div>
              </div>
            </div>

            <div class="tw-grid tw-grid-cols-8 tw-mb-14">
              <div class="tw-col-span-6 tw-col-start-2">
                <div
                    class="tw-relative tw-py-4 tw-px-5 tw-border tw-border-gray-n4 tw-bg-white tw-rounded-lg tw-flex tw-flex-col sm:tw-flex-row tw-space-y-4 sm:tw-space-y-0">
                  <div class="tw-flex tw-text-left tw-items-center tw-flex-1 tw-relative">
                    <span
                        class="tw-inline-flex tw-mr-6 tw-items-center tw-p-1 tw-h-10 tw-w-10 tw-justify-center tw-rounded-full tw-bg-gray-n0">
                      <calendar-icon class="tw-w-4" stroke-width="1"/>
                    </span>

                    <v-dialog
                        ref="dialog"
                        v-model="datesSelected"
                        :return-value.sync="dates"
                        persistent
                        width="320px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <div class="tw-flex-grow sm:tw-flex-grow-0" v-bind="attrs" v-on="on">
                          <label for="date-field"
                                 class="tw-block tw-mb-0 tw-text-sm tw-text-gray-n3 tw-leading-relaxed">
                            Date
                          </label>
                          <div class="tw-mt-1">
                            <span placeholder="Wedding date"
                                  class="tw-appearance-none tw-font-semibold tw-block tw-w-full tw-p-0 tw-placeholder-gray-n4 tw-text-gray-n4 focus:tw-outline-none focus:tw-ring-black focus:border-black sm:tw-text-sm">
                                  {{ dates ? dateFormat(dates) : 'Wedding date' }}
                            </span>
                          </div>
                        </div>
                      </template>
                      <div class="tw-flex tw-flex-col tw-items-center tw-p-6 tw-bg-white">
                        <p class="tw-text-xl tw-font-semibold tw-mb-4 tw-self-center">Choose a date</p>
                        <v-calendar-io is-expanded class="vc-border-none" :min-date="new Date()"
                                       :attributes="attributes" @dayclick="onDayClick"/>
                        <Button class="tw-mt-4 tw-w-full" type="p_dark"
                                @click="$refs.dialog.save(dates);datesSelected = false">
                          Choose this date
                        </Button>
                      </div>
                    </v-dialog>


                    <div class="tw-absolute tw-right-5 tw-bottom-1" v-if="dates">
                      <x-icon @click="dates = null" class="tw-w-4 tw-cursor-pointer"/>
                    </div>
                  </div>
                  <div class="tw-flex tw-text-left tw-items-center tw-flex-1 tw-relative">
                    <span
                        class="tw-inline-flex tw-mr-6 tw-items-center tw-p-1 tw-h-10 tw-w-10 tw-justify-center tw-rounded-full tw-bg-gray-n0">
                      <map-pin-icon class="tw-w-4" stroke-width="1"/>
                    </span>
                    <div class="tw-flex-grow sm:tw-flex-grow-0">
                      <label for="location-field"
                             class="tw-block tw-mb-0 tw-text-sm tw-text-gray-n3 tw-leading-relaxed">
                        Location
                      </label>
                      <div class="tw-mt-1">
                        <v-autocomplete
                            class="text-caption remove-bg-fix location-field-fix"
                            rounded
                            v-model="selectedCities"
                            return-object
                            :items="cities_by_google"
                            :search-input.sync="searchCities"
                            :hide-no-data="!searchCities"
                            :loading="loadingCities"
                            :persistent-hint="false"
                            append-icon=""
                            :clearable="true"
                            :hide-selected="false"
                            :deletable-chips="true"
                            :no-filter="true"
                            :cache-items="false"
                            id="location-field"
                            placeholder="City, State"
                            @click="clearAreas"
                        >
                          <template v-slot:item="data">
                            <v-list-item-content>
                              <v-list-item-title v-text="data.item.description"></v-list-item-title>
                            </v-list-item-content>
                          </template>

                          <template v-slot:selection="data">
                            <v-chip small>
                              {{ data.item.city_name }}
                            </v-chip>
                          </template>
                        </v-autocomplete>
                      </div>
                    </div>
                  </div>
                  <div class="tw-flex tw-text-left tw-items-center tw-flex-1 tw-relative">
                    <span
                        class="tw-inline-flex tw-mr-6 tw-items-center tw-p-1 tw-h-10 tw-w-10 tw-justify-center tw-rounded-full tw-bg-gray-n0">
                      <music-icon class="tw-w-4" stroke-width="1"/>
                    </span>
                    <div class="tw-flex-grow sm:tw-flex-grow-0">
                      <label for="category" class="tw-block tw-mb-0 tw-text-sm tw-text-gray-n3 tw-leading-relaxed">
                        Vendor
                      </label>
                      <div class="tw-mt-1">
                        <select v-model="selectedCategory" name="category" id="category" style="min-width: 180px;"
                                class="tw-appearance-none tw-bg-none tw-font-semibold tw-block tw-w-full tw-p-0 tw-placeholder-gray-n4 tw-text-gray-n4 focus:tw-outline-none focus:tw-ring-black focus:border-black sm:tw-text-sm">
                          <option :value="null">Category</option>
                          <option :value="category.id" v-for="category in categories" v-bind:key="category.id">
                            {{ category.name }}
                          </option>
                        </select>
                      </div>
                    </div>
                    <div class="tw-absolute tw-right-5 tw-bottom-1" v-if="selectedCategory">
                      <x-icon @click="selectedCategory = null" class="tw-w-4 tw-cursor-pointer"/>
                    </div>
                  </div>
                  <div class="tw-flex tw-items-center">
                    <a href="#" @click.prevent="searchFeed()"
                       class="tw-block tw-text-sm tw-no-underline tw-p-4 tw-bg-red tw-leading-relaxed tw-font-semibold tw-rounded-md tw-text-white">
                      Search
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>


    <Promotion class="mt-10" v-if="promotions" :promotion="promotions[0]" />

    <giveaway class="mt-10" v-if="giveaway" :giveaway="giveaway" />


    <div class="tw-bg-red-100 tw-mt-0 sm:tw-mt-20 tw-text-left tw-py-16">
      <div class="tw-max-w-9xl tw-mx-auto tw-px-4 sm:tw-px-8 lg:tw-px-16">
        <div class="md:tw-grid md:tw-grid-cols-8 md:tw-gap-x-8 tw-relative">
          <div class="tw-col-span-6">
            <h2 class="tw-font-semibold tw-text-2rem tw-text-black">Wedding Planning Made Easy</h2>
            <p class="tw-mt-6 tw-mb-3 tw-text-black">
              <span class="tw-font-serif tw-text-red">Mango & Lola’s</span> user-friendly platform was designed by an award-winning wedding planner to help you simplify your wedding planning journey by providing a one stop shop for all your wedding needs.
            </p>
            <p class="tw-mb-0 tw-text-black">
              <span class="tw-font-serif tw-text-red">Mango & Lola</span> simplifies the vendor selection process by allowing you to enter your wedding date, location, and vendor category to quickly see each vendor’s current pricing and availability, as well as customer reviews. Our user-friendly interface is intuitive and will help streamline the vendor selection process tailored to your needs and requirements. Transform your wedding vision into reality by booking all your vendors online with a simple click of the button! Embrace the convenience and joy of a stress-free wedding planning experience and spend time on what truly matters – celebrating your love story ❤️
            </p>
            <router-link
                class="tw-inline-flex mt-4 tw-no-underline tw-self-start tw-items-center tw-px-14 tw-py-4 tw-border tw-bg-red tw-leading-4 tw-font-semibold tw-rounded-md tw-text-white"
                :to="{ name: 'register', query: { type: 1 } }">
              {{ $t('auth.sign_up_now') }}!
            </router-link>
          </div>
          <div class="sm:tw-absolute mt-4 sm:tw-right-0 tw-flex tw-justify-center tw-flex-col tw-items-center">
            <h4 class="tw-my-4 tw-text-black tw-text-lg sm:tw-text-xl tw-font-semibold tw-leading-relaxed">
              Valued Partner
            </h4>
            <img src="@/assets/img/landing/giveaway/AUAJM_Teal_K_RGB.png" alt="Embassy Suites by Hilton Aruba Resort" class="sm:tw-inline-flex sm:tw-left-0 sm:tw-top-0" style="height: 103px">
          </div>
        </div>
      </div>
    </div>

    <section class="tw-max-w-9xl tw-mx-auto tw-px-4 sm:tw-px-8 lg:tw-px-16 tw-mt-20">
      <AppFeat :feats="feats" />
    </section>

    <section class="tw-bg-red-100 tw-mt-20 tw-text-left tw-py-16 tw-text-black">
      <div class="tw-max-w-9xl tw-mx-auto tw-px-4 sm:tw-px-8 lg:tw-px-16">
        <h3 class="tw-font-semibold tw-text-2rem tw-leading-relaxed tw-mb-4">Benefits</h3>
        <div class="tw-grid tw-grid-cols-2 lg:tw-grid-cols-6 tw-gap-x-6 tw-gap-y-7">
          <benefit-box class="tw-col-span-2"
                       text="Free private account with all wedding vendors and invoices in one place">
            <gift-icon class="tw-w-4 tw-h-4 tw-text-gray-n3" />
          </benefit-box>
          <benefit-box class="tw-col-span-2"
                       text="Avoid wasting time with vendors who are unavailable on your wedding date">
            <calendar-icon class="tw-w-4 tw-h-4 tw-text-gray-n3" />
          </benefit-box>
          <benefit-box class="tw-col-span-2" text="Avoid wasting time calling vendors who are not in your price range">
            <clock-icon class="tw-w-4 tw-h-4 tw-text-gray-n3" />
          </benefit-box>
          <benefit-box class="tw-col-span-2 sm:tw-col-start-2"
                       text="Your wedding account data is stored on secure cloud servers">
            <lock-icon class="tw-w-4 tw-h-4 tw-text-gray-n3" />
          </benefit-box>
          <benefit-box class="tw-col-span-2"
                       text="Payment information is fully encrypted along with your personal data">
            <archive-icon class="tw-w-4 tw-h-4 tw-text-gray-n3" />
          </benefit-box>
        </div>
      </div>
    </section>

    <section class="tw-max-w-9xl tw-mx-auto sm:tw-px-8 tw-px-4 lg:tw-px-16 tw-py-20">
      <div class="md:tw-grid md:tw-grid-cols-8 md:tw-gap-x-8 ">
        <div class="tw-col-span-6">
          <h3 class="tw-font-semibold tw-text-2rem tw-leading-relaxed tw-mb-4">Join the <span
              class="tw-font-serif tw-text-red tw-font-normal">Mango & Lola</span> Wedding Community!</h3>
          <p class="tw-mb-0 tw-leading-relaxed">
            We are passionate about weddings and have helped many couples successfully plan their weddings with
            confidence and ease. Start creating your dream wedding today knowing that we built our online platform to
            make the planning process as easy as possible so you can have more fun and enjoy everything your wedding day
            was meant to be.
          </p>
          <p class="tw-my-6 tw-w-3/4 tw-text-black">Creating your account on <span
              class="tw-font-serif tw-text-red">Mango & Lola</span> is fast, easy and free.</p>
          <router-link
              class="tw-inline-flex tw-no-underline tw-self-start tw-items-center tw-px-14 tw-py-4 tw-border tw-bg-red tw-leading-4 tw-font-semibold tw-rounded-md tw-text-white"
              :to="{ name: 'register', query: { type: 1 } }">
            {{ $t('auth.sign_up') }}
          </router-link>
        </div>
      </div>
    </section>

    <section class="tw-max-w-9xl tw-mx-auto tw-px-4 sm:tw-px-8 lg:tw-px-16">
      <div class="tw-flex tw-font-semibold tw-items-center tw-justify-between tw-mb-8">
        <h2 class="tw-text-2xl sm:tw-text-2rem tw-text-black tw-leading-relaxed">{{ $t('spotlight_vendors') }}</h2>
        <router-link class="tw-text-red tw-inline-flex" :to="{ name: 'feed' }">
          See all vendors
          <arrow-right-icon class="tw-w-6 tw-ml-5"/>
        </router-link>
      </div>
      <div class="tw-grid tw-grid-cols-1 md:tw-grid-cols-3 xl:tw-grid-cols-4 md:tw-gap-x-8 tw-gap-y-4">
        <feed-vendor v-for="(feed, index) in feedData" :key="index" :vendor=feed :with-label="false">
        </feed-vendor>
      </div>
    </section>

<!--    <couple-apps class="tw-mt-20" />-->

    <section class="tw-max-w-9xl tw-mx-auto tw-px-4 sm:tw-px-8 lg:tw-px-16 tw-mt-20">
      <h4 class="tw-my-4 tw-text-black tw-text-lg sm:tw-text-xl tw-font-semibold tw-leading-relaxed">Love Notes</h4>
      <div class="tw-grid sm:tw-grid-cols-2 tw-text-black">
        <div class="tw-border tw-border-gray-n1 tw-rounded-lg tw-px-6 tw-py-5 tw-flex tw-flex-col tw-items-center">
          <img src="@/assets/img/landing/quotation.svg" alt="">
          <div class="tw-mt-4">
            <h5 class="tw-font-semibold">K&D</h5>
            <p class="tw-mt-3 tw-text-sm tw-leading-relaxed">
              We used Mango & Lola to book a few select
              vendors for our wedding and were very
              satisfied with the overall experience and
              quality of the vendors. M&L offered a wide
              range of services which made the research
              process easy and honestly enjoyable. We
              highly recommend this company if you are
              planning your own wedding!
            </p>
          </div>
        </div>
      </div>
    </section>



    <SubscriptionForm/>

  </div>
</template>
<script>
import {mapGetters} from "vuex";
import FeedVendor from '@/components/FeedVendor.vue';
/*import CoupleApps from "@/components/landing/CoupleApps";*/
import i18n from "@/i18n";
import {GiftIcon, CalendarIcon, ClockIcon, LockIcon, ArchiveIcon, MapPinIcon, MusicIcon, ArrowRightIcon, XIcon} from "vue-feather-icons";
import Button from '@/components/Button.vue'
import SubscriptionForm from '@/components/SubscriptionForm';
import Giveaway from "@/components/landing/Giveaway.vue";
import Promotion from "@/components/landing/Promotion.vue";

import BenefitBox from "@/components/landing/BenefitBox";
import AppFeat from "@/components/ApplicationFeatures";

export default {
  name: 'HomePage',
  components: {
    FeedVendor,
    /*CoupleApps,*/
    CalendarIcon,
    MapPinIcon,
    MusicIcon,
    ArrowRightIcon,
    XIcon,
    GiftIcon,ClockIcon, LockIcon, ArchiveIcon,
    Button,
    Giveaway,
    Promotion,
    SubscriptionForm,
    BenefitBox,
    AppFeat
  },
  metaInfo() {
    return {
      script: [{
        src: `https://maps.googleapis.com/maps/api/js?key=AIzaSyA5LRbiOg0tXmXao7V80Q71wjUixM_ntbE&libraries=places`,
        async: true,
        defer: true,
        callback: () => this.MapsInit() // will declare it in methods
      }],
      titleTemplate: '%s | The Ultimate Wedding Planning and Booking Platform',
      meta: [
        {
          name: 'description',
          content: i18n.t('description.welcome')
        }
      ],
    }
  },
  data() {
    return {
      dates: null,
      datesSelected: false,
      selectedCities: [],
      cities: [],
      searchCities: null,
      loadingCities: false,
      loadingCategories: false,
      selectedCategory: null,
      categories: [],
      giveaway: null,
      promotions: null,
      // Google Maps API
      location: '',
      searchResults: [],
      service: null,
      cities_by_google: [],
      filterArea: {},
      filterCountry: [],
      features: [
        {
          icon: 'mdi-account-group-outline',
          title: 'Full service online',
          text: 'An easy to navigate wedding platform giving couples tools to browse/book all wedding pros.',
        },
        {
          icon: 'mdi-update',
          title: 'Transparency',
          text: 'Vendor\'s available dates & rates displayed. Option to directly book on site.',
        },
        {
          icon: 'mdi-shield-outline',
          title: 'Secure online booking site',
          text: 'All payments are kept entirely safe giving you peace of mind. Quick and easy online booking. Everything is handled online. Removing the opportunity for double bookings.',
        },
        {
          icon: 'mdi-shield-outline',
          title: 'PLATFORM FOR (PARTNERS) VENDORS',
          text: 'Showcase best work with photos & client reviews. Manage rates and availability. Stay up to date with reservations. Process invoices and payments.',
        },
      ],
      stats: [
        ['24k', 'Vendors'],
        ['330+', 'Booking per day'],
        ['1m', 'Views'],
        ['5m', 'Comments'],
      ],
      feats: [
        "Search Vendors by: Date, Location, Vendor Category",
        "Filter by price & rating",
        "Access vendor Calendars to see their availability",
        "View current vendor pricing unlike other online platforms",
        "Book Vendors directly on Mango & Lola",
        "See all vendor invoices and payments on one screen",
        "Use our expense tracking feature to help manage vendor costs",
      ],
      // END
    }
  },
  mounted() {
    document.getElementById('vid').play();
    this.fetchCategories();
    //this.fetchGiveaways();
    this.fetchPromotions();
    this.loadData();
  },
  methods: {
    onDayClick(day) {
      this.dates = day.id;
    },
    clearAreas() {
      console.log("clearAreas...");
      this.MapsInit();
      //this.selectedCities = null;
    },
    MapsInit() {
      this.service = new window.google.maps.places.AutocompleteService()
    },
    displaySuggestions(predictions, status) {
      if (status !== window.google.maps.places.PlacesServiceStatus.OK) {
        this.searchResults = []
        return
      }
      //this.searchResults = predictions.map(prediction => prediction.description)
      this.searchResults = predictions.map(prediction => prediction.terms);

      let data = predictions.map(prediction => prediction);

      this.cities_by_google = data.map(t => ({

        id: t.place_id,
        description: t.description,
        city_name: t.terms[0].value,
        code: t.terms[1] ? t.terms[1].value : null,
        country: t.terms[2] ? t.terms[2].value : null,
      }));

    },
    fetchAreas(val) {

      let query = val;

      const params = {
        "filter[name]": query.city_name,
        //"filter[country_name]" : query,
        "google_place_id": query.google_place_id,
        "filter[country_code]": query.code.substring(0, 2),
        //"filter[filter_all]" : query,
        "page": this.$route.query.page
      };


      this.$http.get('api/areas', {params})
          .then(({data}) => {
            this.cities = data.data;
            this.filterCountry = this.cities.map(a => a.country_id);
          })
          .catch(error => {
            console.log('Error loading cities data: ' + error)
            this.errored = true
          })
          .finally(() => this.loadingCities = false)
    },
    fetchCategories() {
      this.loadingCategories = true;

      this.$http.get('api/categories')
          .then(({data}) => {
            this.categories = data.data
          })
          .catch(error => {
            console.log('Error loading categories data: ' + error)
            this.errored = true
          })
          .finally(() => this.loadingCategories = false)
    },
    fetchGiveaways() {
      // tmp only getting single
      this.$http.get('api/promotions/1')
          .then(({data}) => {
            this.giveaway = data.data
          })
          .catch(error => {
            console.log('Error loading giveaway: ' + error)
          })
    },
    fetchPromotions() {
      // tmp only getting single
      this.$http.get('api/promotions')
          .then(({data}) => {
            this.promotions = data.data
          })
          .catch(error => {
            console.log('Error loading promotions: ' + error)
          })
    },
    async filterData() {
      const params = {
        "filter[category_in]": this.selectedCategory ? this.selectedCategory : null,
        "filter[availability_dates_in]": this.dates ? this.dates : null,
        "filter[countries_in]": this.selectedCities ? this.selectedCities : null,
        'perPage': 8
      };
      //, 'sort': '-avg_rating'
      //this.params = this.$route.query;
      //console.log(params);
      //await this.$store.dispatch('filter/setFilterParams', params );
      this.fetchFeed(params);

    },
    async loadData() {
      await this.fetchFeed(null);
    },
    async fetchFeed(params) {

      await this.$store.dispatch('feed/fetchFeed', params ? params : {'perPage': 16, 'filter[spotlight]': 1, 'sort':'spotlight'}).then(() => {
        this.loading = false;
      })
      //, 'sort': '-avg_rating'
    },
    async searchFeed() {

      const params = {
        "availability_dates_in": this.dates ? this.dates : null,
        "category_in": this.selectedCategory ? this.selectedCategory : null,
        "countries_in": this.filterCountry ? this.filterCountry.toString() : null,
        "city": this.filterArea ? this.filterArea.city_name : null,
        "place": this.filterArea ? this.filterArea.place_id : null,
        "country_code": this.filterArea ? this.filterArea.code : null,
      };
      this.$router.push({name: 'feed', query: params})


      /*

     const params = {
       "filter[availability_dates_in]": this.dates ? this.dates:null,
       "filter[category_in]": this.selectedCategory ? this.selectedCategory:null,
       "filter[countries_in]": this.selectedCities.toString() ? this.selectedCities.toString():null,
     };

     //console.log("params: ", params);
     await this.$store.dispatch('filter/setFilterParams', params);
     //await this.$store.dispatch('feed/fetchFeed', params)
     await this.$router.push({name: 'feed'})

      */


    }
  },
  watch: {

    searchCities(val) {
      if (this.searchCities == null || this.searchCities.length < 3) {
        return
      }

      setTimeout(function () {
        this.service.getPlacePredictions({
          input: val,
          componentRestrictions: {country: ['us', 'pr']},
          types: ['(regions)']
        }, this.displaySuggestions)
      }.bind(this), 300);
      /*
      this.loadingCities = true;
      setTimeout(function () {
        this.fetchCities(val);
      }.bind(this), 500);

      */

    },
    selectedCities() {

      if (!this.selectedCities || this.selectedCities === null) {
        this.filterArea = {};
        return;
      }

      this.filterArea = {
        city_name: this.selectedCities.city_name,
        code: this.selectedCities.code,
        country: this.selectedCities.country,
        place_id: this.selectedCities.id,
        google_place_id: this.selectedCities.id

      };
      this.fetchAreas(this.filterArea);
    },
    selectedCategory() {
      if (!this.selectedCategory) {
        //this.filterData();
      }
    },
    dates() {
      if (!this.dates) {
        //this.filterData();
      }
    }
  },
  computed: {
    ...mapGetters({
      check: 'auth/check',
      feedData: 'feed/getFeedData',
      getFeedLoading: 'feed/getFeedLoading',
    }),
    attributes() {
      return [
        {
          highlight: {
            class: 'tw-bg-red',
          },
          dates: this.dates,
        },
      ]
    },
  },
}
</script>
