<template>
  <section class="tw-bg-red-100 tw-text-left tw-py-8 tw-px-8 sm:tw-px-36 tw-rounded-lg">
    <div class="mb-8 tw-relative" :style="{'height': '17.5rem'}">
      <img src="@/assets/img/landing/giveaway/label.svg" alt="Giveaway" class="tw-inline-flex tw-absolute tw-left-0 tw-top-6">
      <img :src="giveaway.image" :alt="giveaway.title" class="tw-object-cover tw-object-center tw-rounded-lg tw-w-full" :style="{'height': '17.5rem'}">
    </div>
    <div class="tw-flex tw-relative tw-items-center tw-justify-center tw-flex-col sm:tw-flex-row" style="min-height: 103px">
      <img src="@/assets/img/landing/giveaway/AUAJM_Teal_K_RGB.png" alt="Embassy Suites by Hilton Aruba Resort" class="sm:tw-inline-flex sm:tw-absolute sm:tw-left-0 sm:tw-top-0" style="height: 103px">
      <div class="tw-text-center sm:tw-relative">
        <h2 class="tw-text-gray-n4 tw-font-bold tw-text-2rem sm:tw-text-2.5rem tw-relative tw-z-10">Win a Honeymoon in</h2>
        <img src="@/assets/img/landing/giveaway/Aruba.svg" alt="Aruba" class="tw-w-40 sm:tw-w-auto tw-inline-flex sm:tw-absolute tw--right-44 tw--bottom-2">
        <router-link
            class="tw-mt-5 active:tw-bg-secondary active:tw-border-red focus:tw-ring-red focus:tw-ring-2 focus:tw-border-red hover:tw-border-red tw-text-red tw-border-solid tw-border-2 tw-border-secondary tw-bg-white tw-h-14 tw-no-underline tw-items-center tw-px-8 tw-py-4 tw-leading-4 tw-font-semibold tw-rounded-xl tw-inline-block tw-justify-center tw-gap-x-3 focus:tw-outline-none disabled:tw-pointer-events-none"
            :to="{name: 'giveaway.show', params: { id: giveaway.id }}">
          Enter Sweepstakes
        </router-link>
        <img src="@/assets/img/landing/giveaway/flamingo.svg" alt="Flamingo" class="tw-hidden sm:tw-inline-flex tw-absolute tw--right-56 tw--bottom-0">
      </div>

    </div>
  </section>

</template>

<script>
export default {
  name: 'Giveaway',
  props: [
    'giveaway',
  ]
}
</script>
