<template>
  <section class="tw-bg-red-100 tw-text-left tw-py-8 tw-px-8 sm:tw-px-36 tw-rounded-lg">
    <div class="mb-8 tw-relative" :style="{'height': '17.5rem'}">

      <img :src="promotion.image" :alt="promotion.title" class="tw-object-cover tw-object-center tw-rounded-lg tw-w-full" :style="{'height': '17.5rem'}">
    </div>
    <div class="tw-flex tw-relative tw-items-center tw-justify-center tw-flex-col sm:tw-flex-row" style="min-height: 103px">
      <div class="tw-text-center sm:tw-relative">
        <h2 class="tw-text-gray-n4 tw-font-bold tw-text-2rem sm:tw-text-2.5rem tw-relative tw-z-10">Promotions</h2>

        <router-link
            class="tw-mt-5 active:tw-bg-secondary active:tw-border-red focus:tw-ring-red focus:tw-ring-2 focus:tw-border-red hover:tw-border-red tw-text-red tw-border-solid tw-border-2 tw-border-secondary tw-bg-white tw-h-14 tw-no-underline tw-items-center tw-px-8 tw-py-4 tw-leading-4 tw-font-semibold tw-rounded-xl tw-inline-block tw-justify-center tw-gap-x-3 focus:tw-outline-none disabled:tw-pointer-events-none"
            :to="{name: 'promotions.show', params: { id: promotion.id }}">
          View {{ promotion.title }}
        </router-link>

      </div>

    </div>
  </section>

</template>

<script>
export default {
  name: 'Promotion',
  props: [
    'promotion',
  ]
}
</script>
