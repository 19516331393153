<template>
  <div class="tw-absolute tw-top-0 tw-left-0">

    <svg v-if="spotlight" width="130" height="130" viewBox="0 0 130 130" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0.0214949 129.223L14.5907 129.223L14.5907 105.045L0.0214928 105.045L0.0214949 129.223Z" fill="#BC3131"/>
      <path d="M104.872 14.5933L129.011 14.5933L129.011 9.63211e-05L104.872 9.84313e-05L104.872 14.5933Z" fill="#BC3131"/>
      <path d="M0.0215149 129.223L129.011 0.021637L128.989 4.57783e-05L83.191 4.97821e-05C80.691 5.00006e-05 78.3202 0.993077 76.553 2.76325L2.75863 76.6789C0.991359 78.4491 -3.47469e-05 80.8453 -3.45299e-05 83.3279L-3.05176e-05 129.223L0.0215149 129.223Z" fill="#E14D4D"/>
      <path d="M21.9276 84.4744C22.6603 85.2084 22.9836 86.0718 22.8759 87.0217C22.7681 87.9715 22.2508 88.943 21.3026 89.8928C20.4189 90.7779 19.4922 91.3824 18.4792 91.7278L16.8628 90.1087C17.6603 89.8065 18.3068 89.5043 18.781 89.2236C19.2551 88.943 19.6646 88.6408 19.9879 88.3169C20.3758 87.9284 20.5913 87.5614 20.656 87.216C20.7207 86.8706 20.5913 86.5468 20.3112 86.2661C20.1603 86.115 19.9663 86.0071 19.7508 85.9639C19.5353 85.9207 19.2767 85.9423 18.9965 86.0071C18.7163 86.0719 18.1775 86.2446 17.3801 86.5252C16.6473 86.7842 16.0439 86.9354 15.5697 86.9785C15.0956 87.0217 14.643 86.9569 14.2119 86.8274C13.7809 86.6979 13.3714 86.4172 12.9835 86.0503C12.2507 85.3163 11.9274 84.5175 12.0136 83.5893C12.0998 82.6826 12.5524 81.7975 13.436 80.934C13.8671 80.5023 14.3197 80.1569 14.8154 79.8546C15.3111 79.574 15.8499 79.315 16.4533 79.0559L17.2508 80.9772C16.6258 81.2362 16.1516 81.4737 15.7852 81.6896C15.4188 81.9055 15.1171 82.1429 14.8369 82.4236C14.5137 82.7474 14.3412 83.0928 14.3197 83.4166C14.2981 83.7404 14.4059 84.0426 14.6645 84.2801C14.8154 84.4312 14.9878 84.5391 15.1818 84.5823C15.3757 84.6255 15.5913 84.6255 15.8499 84.5823C16.1085 84.5391 16.6689 84.3664 17.5309 84.0642C18.6732 83.6541 19.5568 83.5029 20.2034 83.5893C20.8069 83.6325 21.3888 83.9563 21.9276 84.4744Z" fill="white"/>
      <path d="M25.6992 73.9613C26.5828 74.8463 26.9708 75.7962 26.9061 76.8108C26.8199 77.8254 26.2811 78.84 25.2466 79.8762L24.4923 80.6318L27.4018 83.5461L25.6561 85.2947L17.4663 77.0915L20.0741 74.4794C21.0655 73.4863 22.0353 72.9467 22.9836 72.8603C23.975 72.7308 24.8587 73.0978 25.6992 73.9613ZM23.0914 79.1854L23.6733 78.6026C24.2121 78.0629 24.4923 77.5664 24.5569 77.0915C24.6216 76.6165 24.4276 76.1632 24.0397 75.7746C23.6302 75.3645 23.2207 75.1702 22.8112 75.2133C22.4017 75.2349 21.9491 75.494 21.475 75.9689L20.6776 76.7676L23.0914 79.1854Z" fill="white"/>
      <path d="M36.2166 66.492C37.5744 67.852 38.2856 69.2336 38.3287 70.6368C38.3933 72.04 37.7899 73.3784 36.5399 74.6305C35.2899 75.8825 33.9536 76.487 32.5527 76.4222C31.1518 76.3575 29.7725 75.6451 28.4147 74.2851C27.057 72.925 26.3457 71.5434 26.3026 70.1403C26.2595 68.7371 26.863 67.4202 28.1346 66.1466C29.3846 64.8945 30.7208 64.29 32.1217 64.3332C33.4795 64.4196 34.8588 65.132 36.2166 66.492ZM30.2467 72.4717C31.1518 73.3784 32.0355 73.8965 32.8329 74.026C33.6519 74.1339 34.3847 73.8533 35.0959 73.1625C36.4752 71.7809 36.2597 70.1618 34.4062 68.3053C32.5527 66.4488 30.9579 66.2329 29.5785 67.5929C28.8889 68.2837 28.6087 69.0393 28.7165 69.8596C28.8242 70.7015 29.3199 71.565 30.2467 72.4717Z" fill="white"/>
      <path d="M45.2038 65.7363L43.4581 67.4849L36.7123 60.728L34.4925 62.9515L33.0485 61.5052L39.2339 55.3096L40.6779 56.7559L38.458 58.9794L45.2038 65.7363Z" fill="white"/>
      <path d="M48.6737 62.2392L40.4839 54.0359L42.2296 52.2874L48.997 59.0658L52.316 55.7414L53.76 57.1877L48.6737 62.2392Z" fill="white"/>
      <path d="M55.1609 55.7413L46.9711 47.538L48.7168 45.7894L56.9066 53.9927L55.1609 55.7413Z" fill="white"/>
      <path d="M57.4669 44.2136L60.7213 40.9539L64.9671 45.2066C64.6222 45.8974 64.2343 46.5234 63.8464 47.0631C63.4584 47.6028 63.0058 48.0993 62.5317 48.5958C61.3032 49.8263 59.9885 50.4092 58.6092 50.3444C57.2299 50.2797 55.8505 49.5457 54.4928 48.1857C53.1781 46.8688 52.51 45.4441 52.5315 43.9545C52.5531 42.465 53.2212 41.0402 54.579 39.6802C55.4195 38.8383 56.3893 38.1907 57.51 37.7589L58.329 39.7234C57.4238 40.0256 56.6695 40.5005 56.023 41.1481C55.2686 41.9037 54.9238 42.7456 54.9669 43.7171C55.01 44.6669 55.4626 45.5736 56.3247 46.4371C57.2083 47.3222 58.0704 47.8187 58.9109 47.9266C59.7515 48.0346 60.5058 47.7323 61.1955 47.0415C61.5618 46.6746 61.8851 46.2644 62.1868 45.8326L60.4842 44.1272L58.9325 45.6815L57.4669 44.2136Z" fill="white"/>
      <path d="M73.9974 36.8954L72.2732 38.6224L68.7387 35.082L65.4843 38.3418L69.0189 41.8821L67.2732 43.6307L59.0834 35.4274L60.8291 33.6789L64.0404 36.8954L67.2947 33.6357L64.0835 30.4191L65.8076 28.6921L73.9974 36.8954Z" fill="white"/>
      <path d="M79.2345 31.6497L77.4888 33.3983L70.743 26.6198L68.5232 28.8649L67.0792 27.4185L73.2646 21.2229L74.7086 22.6693L72.4672 24.8928L79.2345 31.6497Z" fill="white"/>
      <g clip-path="url(#clip0_3287_6772)">
        <path d="M81.8091 12.4252L86.1002 13.88L89.7351 11.1723L89.6754 15.7021L93.3745 18.3181L89.0466 19.6628L87.7019 23.9906L85.0859 20.2915L80.5561 20.3512L83.2639 16.7163L81.8091 12.4252Z" fill="#F3C781"/>
      </g>
      <defs>
        <clipPath id="clip0_3287_6772">
          <rect width="15.5772" height="15.5772" fill="white" transform="translate(75.3838 17.0146) rotate(-45)"/>
        </clipPath>
      </defs>
    </svg>


    <svg v-else-if="featured" width="130" height="130" viewBox="0 0 130 130" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M-5.05447e-05 129.223L14.5692 129.223L14.5692 105.045L-4.8431e-05 105.045L-5.05447e-05 129.223Z" fill="#C1A08A"/>
      <path d="M104.872 14.5933L129.011 14.5933L129.011 0.000108719L104.872 0.000106609L104.872 14.5933Z" fill="#C1A08A"/>
      <path d="M-2.96874e-05 129.223L129.011 0.0216417L128.989 5.94432e-05L83.191 5.54394e-05C80.691 5.52208e-05 78.3202 0.993078 76.553 2.76325L2.75863 76.6789C0.991355 78.4491 -2.54581e-05 80.8453 -2.56752e-05 83.3278L-2.96874e-05 129.223Z" fill="#D8C3B5"/>
      <path d="M29.1517 83.5421L27.4491 85.2475L19.2593 77.0442L23.9576 72.3381L25.3801 73.7629L22.3843 76.742L24.4964 78.8576L27.2767 76.0728L28.6991 77.4975L25.9189 80.2823L29.1517 83.5421Z" fill="#523C39"/>
      <path d="M38.4622 74.2162L33.7423 78.9439L25.5525 70.7406L30.2724 66.0129L31.6948 67.4377L28.6991 70.4168L30.4879 72.2086L33.2681 69.4238L34.6906 70.8485L31.9104 73.6333L34.0225 75.7489L36.9966 72.7698L38.4622 74.2162Z" fill="#523C39"/>
      <path d="M45.1864 67.4593L42.6433 66.0992L39.6476 69.0999L41.0053 71.6472L39.1303 73.5253L33.8069 62.3862L35.9406 60.249L47.083 65.5811L45.1864 67.4593ZM40.7683 65.063C38.4406 63.8326 37.1475 63.1418 36.8458 62.9906C36.5441 62.8179 36.307 62.6884 36.1561 62.5805C36.501 63.1849 37.3415 64.7176 38.6346 67.2002L40.7683 65.063Z" fill="#523C39"/>
      <path d="M51.2641 61.3933L49.5184 63.1419L42.7726 56.385L40.5528 58.6085L39.1088 57.1622L45.2942 50.9666L46.7382 52.4129L44.5183 54.6364L51.2641 61.3933Z" fill="#523C39"/>
      <path d="M53.2253 43.0222L58.5272 48.3327C59.1306 48.9372 59.5186 49.6064 59.7125 50.3404C59.9065 51.0744 59.8634 51.8083 59.5832 52.5639C59.3246 53.3195 58.8289 54.0534 58.1392 54.7442C57.0832 55.802 56.0056 56.3417 54.8633 56.3849C53.7426 56.4281 52.7081 55.9747 51.7814 55.0465L46.5011 49.7575L48.2253 48.0305L53.2469 53.0604C53.8719 53.6864 54.4754 54.0318 55.0142 54.075C55.553 54.1182 56.1349 53.8376 56.7168 53.2547C57.2771 52.6934 57.5573 52.1321 57.5142 51.5709C57.4711 51.0096 57.1263 50.4267 56.5013 49.7791L51.4796 44.7492L53.2253 43.0222Z" fill="#523C39"/>
      <path d="M62.0186 44.3174L65.1652 47.4692L63.4195 49.2178L55.2297 41.0145L57.622 38.6183C58.7427 37.4958 59.7557 36.8913 60.704 36.7618C61.6522 36.6323 62.5359 36.9777 63.3549 37.8196C63.829 38.2945 64.1307 38.8558 64.2385 39.5034C64.3463 40.151 64.26 40.8202 64.0014 41.511C67.0834 42.1155 69.0662 42.5041 69.9929 42.6768L68.0748 44.5981L62.9669 43.4107L62.0186 44.3174ZM60.5962 42.9142L61.1566 42.353C61.6954 41.8133 62.0186 41.2952 62.1048 40.8634C62.1911 40.4101 62.0186 39.9999 61.6307 39.6113C61.2428 39.2228 60.8333 39.0717 60.4022 39.1796C59.9712 39.2875 59.4755 39.6113 58.9151 40.1726L58.3979 40.6907L60.5962 42.9142Z" fill="#523C39"/>
      <path d="M75.7257 36.8915L71.0058 41.6192L62.816 33.4159L67.536 28.6882L68.9584 30.113L65.9842 33.0921L67.773 34.8838L70.5532 32.0991L71.9757 33.5238L69.1955 36.3086L71.3076 38.4242L74.2818 35.4451L75.7257 36.8915Z" fill="#523C39"/>
      <path d="M80.0577 24.1764C81.4155 25.5364 82.0621 26.9396 81.9974 28.4291C81.9543 29.9187 81.2 31.3866 79.756 32.833L77.4284 35.1644L69.2386 26.9612L71.8033 24.3923C73.1395 23.0538 74.5404 22.363 75.9844 22.3414C77.4284 22.2983 78.7861 22.9243 80.0577 24.1764ZM78.2905 26.0329C76.5232 24.2627 74.8637 24.1764 73.3119 25.7307L72.3852 26.6589L77.7301 32.0127L78.4844 31.2571C80.1439 29.5733 80.0793 27.8463 78.2905 26.0329Z" fill="#523C39"/>
    </svg>

  </div>
</template>
<script>
export default {
  props: [
      'featured',
      'spotlight',
  ],
}

</script>